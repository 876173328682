<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'eligibleToInvite'; let row">
    <button type="button" class="btn btn-primary" (click)="invite(row)" *ngIf="row.eligibleToInvite === true">
      {{ 'Invite' | translate }}
    </button>
  </ng-container>
</lib-generic-list>
