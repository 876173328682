import { Component, Input, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContentStoreService, MxLoggerService, AuthStoreService, NavMenu } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { User } from 'src/app/core/model/user';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideHeaderMenu = false;
  @ViewChild('header', { static: true }) headerElement: ElementRef<HTMLElement>;

  headerFixed = environment.headerFixed;

  authenticatedUser$ = this.authStore.authenticatedUser$.pipe(untilDestroyed(this));
  userRole: string;
  isDistributor = false;
  isInvoiceUploadOpen = false;

  headerMenu: NavMenu;

  totalSales: number;
  totalRebate: number;
  quarter: string;

  currencySymbol$ = this.userStore.currency$.pipe(
    filter(Boolean),
    map((currency) => currency.symbol)
  );

  constructor(
    private authStore: AuthStoreService,
    private renderer: Renderer2,
    private contentStore: ContentStoreService,
    private mxLogger: MxLoggerService,
    private userStore: UserStoreService
  ) {}

  ngOnInit(): void {
    this.contentStore.headerMenu$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((headerMenu) => {
      this.mxLogger.debug('HeaderComponent', 'ngOnInit() headerMenu=', headerMenu);

      this.headerMenu = headerMenu;
    });

    this.userStore.rebateSummary$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((rebateSummary) => {
      this.totalRebate = rebateSummary.rebateDueLocalCurrency ?? 0;
      this.totalSales = rebateSummary.qtrRevLocalCurrency ?? 0;
      this.quarter = rebateSummary.yrQtr ?? '';
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((userSettings: any) => {
      this.userRole = (userSettings?.user as User)?.userRole?.name ?? '';
      this.isDistributor = this.userRole === 'lenovo.elevate.disti.access';
    });

    this.userStore.invoiceDetailsQuarters$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((invoiceDetailsQuarters) => {
        this.isInvoiceUploadOpen =
          invoiceDetailsQuarters?.invoiceUploadFeatureIsOpen || invoiceDetailsQuarters?.invoiceUploadExtendedPeriodOpen;
      });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (environment.headerFixed) {
      if (window.scrollY > 0) {
        this.renderer.addClass(this.headerElement.nativeElement, 'scrolled');
      } else {
        this.renderer.removeClass(this.headerElement.nativeElement, 'scrolled');
      }
    }
  }
}
