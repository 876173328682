import { Component, Input, OnInit } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { RebateSummary } from 'src/app/core/model/lenovo/rebate-summary';
import { SalesTargetOption } from 'src/app/core/model/lenovo/sales-target-option';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-sales-target-list',
  templateUrl: './sales-target-list.component.html',
  styleUrls: ['./sales-target-list.component.scss'],
})
export class SalesTargetListComponent implements OnInit {
  @Input() showCompletedCheck = false;

  salesTargetOptions: SalesTargetOption[];

  rebateSummary: RebateSummary | undefined;

  constructor(private userStore: UserStoreService, private authStore: AuthStoreService) {}

  ngOnInit(): void {
    this.userStore.salesTargetOptions$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((salesTargetOptions) => {
      this.salesTargetOptions = salesTargetOptions;
    });

    this.userStore.rebateSummary$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((rebateSummary) => {
      this.rebateSummary = rebateSummary;
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((userSettings) => {
      const canImpersonate = userSettings.claims.find((c: string) => c === 'user.impersonate');

      if (!canImpersonate) {
        this.userStore.getSalesTargetOptions();
      }
    });
  }
}
