/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class DistributorGuard implements CanActivate {
  constructor(private authStore: AuthStoreService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authStore.userSettings$.pipe(filter(Boolean), take(1)).pipe(
      map(() => {
        if (this.isDistributor()) {
          if (route.routeConfig?.path !== 'distributors') {
            return this.router.parseUrl('/distributors');
          }

          return true;
        }

        return route.routeConfig?.path !== 'home' ? this.router.parseUrl('/home') : true;
      })
    );
  }

  private isDistributor() {
    const userRole = (this.authStore.userSettings?.user as User)?.userRole;
    return userRole?.name === 'lenovo.elevate.disti.access';
  }
}
