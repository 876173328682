import { Component, Input, OnInit } from '@angular/core';
import { AuthStoreService, NavMenu } from '@motivforce/mx-library-angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  @Input()
  headerMenu: NavMenu;

  isStarSellerAllowed = false;

  constructor(private offcanvasService: NgbOffcanvas, private authStore: AuthStoreService) {}

  ngOnInit(): void {
    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((userSettings: any) => {
      const companies = userSettings?.user?.companies;

      if (companies && companies.length > 0) {
        this.isStarSellerAllowed = companies[0].elevateCompanyDetails?.isStarSellerAllowed;
      }
    });
  }

  open(content: any) {
    this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title', panelClass: 'main-menu-panel' });
  }
}
