import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, TranslateResolver } from '@motivforce/mx-library-angular';

import { DistributorReferralPageComponent } from './core/components/distributor-referral-page/distributor-referral-page.component';
import { DistributorGuard } from './core/guards/distributor-guard';
import { UserGuard } from './core/guards/user-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
    canActivate: [],
    data: {
      isLanding: true,
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [],
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [],
  },
  {
    path: 'thank-you',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard, DistributorGuard, UserGuard],
  },
  {
    path: 'sales-accelerators',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Sales accelerators') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./sales-accelerators/sales-accelerators.module').then((m) => m.SalesAcceleratorsModule),
    canActivate: [AuthGuard, UserGuard],
  },
  {
    path: 'elevate-partners',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Elevate partners') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./elevate-partners/elevate-partners.module').then((m) => m.ElevatePartnersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'terms-and-conditions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Terms and conditions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [],
  },
  {
    path: 'account-summary',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Account summary') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./account-summary/account-summary.module').then((m) => m.AccountSummaryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account-summary-statement',
    loadChildren: () => import('./account-summary/account-summary.module').then((m) => m.AccountSummaryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'promotions',
    loadChildren: () => import('./promotion/promotion.module').then((m) => m.PromotionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'program-overview',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Program overview') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./program-overview/program-overview.module').then((m) => m.ProgramOverviewModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'enrolment',
    data: {
      breadcrumbLabel: (data: any) => data.translate.instant('Enrolment'),
      hideHeaderMenu: true,
    },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./enrolment/enrolment.module').then((m) => m.EnrolmentModule),
    canActivate: [AuthGuard, UserGuard],
  },
  {
    path: 'company-account',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Company account') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./company-account/company-account.module').then((m) => m.CompanyAccountModule),
    canActivate: [AuthGuard, UserGuard],
  },
  {
    path: 'distributors',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Distributors') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./distributors/distributors.module').then((m) => m.DistributorsModule),
    canActivate: [AuthGuard, DistributorGuard],
  },
  {
    path: 'distributor-referral',
    component: DistributorReferralPageComponent,
  },
  {
    path: 'invoice-upload',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Invoice upload') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./invoice-upload/invoice-upload.module').then((m) => m.InvoiceUploadModule),
    canActivate: [AuthGuard, UserGuard],
  },
  {
    path: 'not-allowed',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Not allowed') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./not-allowed/not-allowed.module').then((m) => m.NotAllowedModule),
    canActivate: [],
  },
  {
    path: '**',
    redirectTo: '/landing',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
