<div class="px-3 py-2 modal-header">
  <h3 class="modal-title" id="modal-basic-title">
    {{ 'Confirm selection' | translate }}
  </h3>
</div>

<div class="m-1 pt-2 d-flex flex-column justify-content-center align-items-center modal-body">
  <h3 class="my-3">{{ businessUnit | translate }}</h3>
  <h4 class="mt-2">{{ contentTitle | translate }}</h4>
  <h4>{{ contentDescription | translate }}</h4>
  <div class="mt-2 d-flex justify-content-center align-items-center footer-copy">{{ footerCopyText | translate }}</div>
</div>
<div class="mx-2 pb-2 d-flex justify-content-space-around align-items-center modal-footer">
  <button class="btn btn-outline-primary" (click)="close()">{{ 'Cancel' | translate }}</button>
  <button class="btn btn-primary" (click)="onConfirm()">
    {{ 'Confirm' | translate }}
  </button>
</div>
