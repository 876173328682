import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { InvoiceDetails } from 'src/app/core/model/lenovo/invoice-details';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-invoice-upload-form',
  templateUrl: './invoice-upload-form.component.html',
  styleUrls: ['./invoice-upload-form.component.scss'],
})
export class InvoiceUploadFormComponent implements OnInit {
  @Input() isPcsd: boolean;
  @Input() checkBankDetails = false;

  form = this.formBuilder.group({
    invoiceNumber: ['', Validators.required],
    invoiceDate: ['', Validators.required],
    quarter: [{ value: null, disabled: true }, Validators.required],
    localCurrency: ['', Validators.required],
    rebateAmountInLocalCurrency: ['', Validators.required],
    invoiceFile: '',
  });

  quartersList: any[];

  details: InvoiceDetails;

  bankDetailsFormValid = false;

  selectedFile: File | undefined;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userStore: UserStoreService,
    private dialogService: DialogNgbService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userStore.currentInvoiceDetails$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((currentInvoiceDetails) => {
        this.details = currentInvoiceDetails.invoiceDetails;

        this.form.patchValue(this.details);
      });
  }

  onFileSelected(event: any): void {
    if (!this.form.valid) {
      this.dialogService.openValidation(
        [this.translateService.instant('Please fill all required fields')],
        this.translateService.instant('Error')
      );
      return;
    }
    const selectedFile: File = event.target.files[0];

    if (selectedFile.name.length > 50) {
      this.dialogService.openValidation(
        [this.translateService.instant(`Selected file name is longer that 50 characters.`)],
        this.translateService.instant('Error')
      );
      return;
    }

    this.selectedFile = selectedFile;

    this.userStore
      .uploadInvoiceFile({ ...this.details, ...this.form.value } as InvoiceDetails, this.selectedFile!)
      .then((result) => {
        this.details.invoiceUrl = result || '';
      });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.userStore.submitInvoice({ ...this.details, ...this.form.value } as InvoiceDetails);
    } else {
      this.dialogService.openValidation(
        [this.translateService.instant('Please fill all required fields')],
        this.translateService.instant('Error')
      );
    }
  }
}
