import { Component, OnInit } from '@angular/core';
import { AuthStoreService, Banner, ContentStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, take } from 'rxjs';
import { CustomCarouselBanner } from 'src/app/core/model/custom-carousel-banner';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-home-banner-section',
  templateUrl: './home-banner-section.component.html',
  styleUrls: ['./home-banner-section.component.scss'],
})
export class HomeBannerSectionComponent implements OnInit {
  banner: Banner | null;

  banners: CustomCarouselBanner[];

  userFirstName: string;
  companyName: string;

  user$ = this.authStore.userSettings$.pipe(map((settings) => (settings ? settings.user : null)));
  accountSummary$ = this.userStore.accountSummary$;

  constructor(
    private contentStore: ContentStoreService,
    private authStore: AuthStoreService,
    private userStore: UserStoreService
  ) {}

  ngOnInit(): void {
    this.contentStore.banner$.pipe(untilDestroyed(this)).subscribe((banner) => {
      this.banner = banner;
      this.getBannersContent();
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((settings) => {
      this.userFirstName = settings.user.firstName;
      this.companyName = (settings.user as any).companies.find((c: any) => c.isPrimary)?.name ?? '';
    });
  }

  private getBannersContent(): void {
    if (this.banner?.listId) {
      this.contentStore.currentContent$
        .pipe(
          untilDestroyed(this),
          filter((content) => content?.id === this.banner?.listId),
          take(1)
        )
        .subscribe((content) => {
          if (content) {
            this.banners = content.children.map((childContent) => {
              const image = childContent.fields.find((f) => f.name === 'Banner Image');
              const altText = childContent.fields.find((f) => f.name === 'Image Alternative Text');

              return {
                image: image?.value.url,
                url: childContent.properties.url?.value,
                target: childContent.properties.target?.value,
                altText: altText?.value.value,
              };
            });
          }
        });

      this.contentStore.getContentById(this.banner.listId, true);
    }
  }
}
