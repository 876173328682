import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';
import { Distributor } from 'src/app/core/model/lenovo/distributor';
import { SalesTargetOption } from 'src/app/core/model/lenovo/sales-target-option';

@Component({
  selector: 'app-target-selection',
  templateUrl: './target-selection.component.html',
  styleUrls: ['./target-selection.component.scss'],
})
export class TargetSelectionComponent implements OnInit {
  @Input() salesTargetOptions: SalesTargetOption[];
  private _distributors: Distributor[];
  @Input() allowDistributorUpdate = false;
  @Input() isPcsd: boolean;
  private _formGroup: FormGroup;
  @Input() isSingleForm = false;
  @Input() showActivateButton = false;

  @Output() targetConfirmedChange = new EventEmitter<boolean>();
  @Output() distributorChange = new EventEmitter<number>();

  targetOptionsBase: SalesTargetOption[];
  targetOptionsTop: SalesTargetOption[];

  currency: string;

  targetSelected?: number;
  selectedTargetText: string;
  selectedTargetMinValue: number;
  baseFooterCopyKey = `Don't miss out! Choose the Top Target Group for both targets to unlock eligibility for StarSeller benefits.`;
  topFooterCopyKey = 'Choosing the Top Target Group for both targets opens eligibility for StarSeller benefits!';
  footerCopyText: string;

  targetConfirmed = false;

  showFullActivateTarget = true;
  updateTargetEnabled = false;
  allowTargetUpdate = true;

  currentDistributorName: string;

  constructor(
    private dialog: DialogNgbService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) {}

  @Input()
  get distributors(): Distributor[] {
    return this._distributors;
  }

  set distributors(value: Distributor[]) {
    this._distributors = value;

    this.setCurrentDistributorName();
  }

  @Input()
  get formGroup(): FormGroup {
    return this._formGroup;
  }

  set formGroup(value: FormGroup) {
    this._formGroup = value;

    this.setCurrentDistributorName();
  }

  private setCurrentDistributorName(): void {
    if (this.distributors && this.formGroup) {
      this.currentDistributorName =
        this.distributors.find((d) => d.companyId === this.formGroup.controls['chosenDistributorId'].value)
          ?.distributorName || '';
    }
  }

  ngOnInit(): void {
    this.targetOptionsBase = this.salesTargetOptions
      .filter((target) => target.targetOption === 1)
      .sort((a, b) => a.salesTarget - b.salesTarget);
    this.targetOptionsTop = this.salesTargetOptions
      .filter((target) => target.targetOption === 2)
      .sort((a, b) => a.salesTarget - b.salesTarget);

    this.currency = this.targetOptionsBase[0].currency.symbol;

    this.showFullActivateTarget = this.formGroup.controls['targetOption'].value === null && !this.showActivateButton;
    this.allowTargetUpdate = this.formGroup.controls['isTargetUpdateAllowed'].value === true;

    if (this.formGroup.controls['targetOption'].value) {
      this.targetSelected = this.formGroup.controls['targetOption'].value;
      this.setTargetText(this.targetSelected || 0);
    }
  }

  onTargetSelectionChange(target: number): void {
    this.targetSelected = target;
    this.formGroup.get('targetOption')?.setValue(target);

    this.setTargetText(target);
    this.updateTargetEnabled = true;
  }

  setTargetText(target: number): void {
    if (target === 1) {
      this.selectedTargetText = 'Base Target Group';
      this.selectedTargetMinValue = this.targetOptionsBase[0].salesTarget;
      this.footerCopyText = this.baseFooterCopyKey;
    } else if (target === 2) {
      this.selectedTargetText = 'Top Target Group';
      this.selectedTargetMinValue = this.targetOptionsTop[0].salesTarget;
      this.footerCopyText = this.topFooterCopyKey;
    }
  }

  showTargetOptions(): void {
    this.showFullActivateTarget = false;
  }

  activateTarget(): void {
    if (!this.targetSelected) {
      return;
    }
    const content = `${this.translateService.instant('from')} ${this.currency} ${this.selectedTargetMinValue}`;
    this.dialog
      .openFeatureDialog('targetConfirmationDialog', [
        this.isPcsd ? 'PCSD' : 'ISG',
        this.selectedTargetText,
        content,
        this.footerCopyText,
      ])
      .then((result: any) => {
        this.targetConfirmed = result as boolean;
        this.targetConfirmedChange.emit(this.targetConfirmed);
        if (this.targetConfirmed) {
          this.updateTargetEnabled = false;
          this.formGroup.disable();
        }
      });
  }

  deactivateTarget(): void {
    this.targetConfirmed = false;
    this.targetConfirmedChange.emit(this.targetConfirmed);
    this.targetSelected = undefined;
    this.formGroup.enable();
    this.formGroup.get('targetOption')?.reset();
  }

  updateDistributor(): void {
    const distributorName = this.distributors.find(
      (d) => d.companyId === this.formGroup.controls['chosenDistributorId'].value
    )?.distributorName;
    if (!distributorName) {
      return;
    }
    this.dialog
      .openFeatureDialog('targetConfirmationDialog', [
        '',
        this.translateService.instant('Distributor'),
        distributorName,
      ])
      .then((result: any) => {
        if (result === true) {
          this.distributorChange.emit(this.formGroup.get('chosenDistributorId')?.value);
          this.currentDistributorName = distributorName;
        }
      });
  }
}
