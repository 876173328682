<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [serverPaging]="showPagination"
  [showPagination]="showPagination"
  [defaultPagination]="false"
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  [rowHeight]="'40px'"
  (stateChanged)="stateChanged.emit($event)"
>
</lib-generic-list>
