import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthStoreService, ContentGqlService, ContentStoreService } from '@motivforce/mx-library-angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { IsLoadingService } from '@service-work/is-loading';
import { filter, map, skip, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  user$ = this.authStore.userSettings$.pipe(map((settings) => (settings ? settings.user : null)));

  profileMenu: any;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private authStore: AuthStoreService,
    private router: Router,
    private isLoadingService: IsLoadingService,
    private contentStore: ContentStoreService,
    private contentGql: ContentGqlService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => this.offcanvasService.dismiss());
  }

  open(content: any) {
    this.offcanvasService.open(content, {
      ariaLabelledBy: 'offcanvas-basic-title',
      position: 'end',
      panelClass: 'profile-menu-panel',
      scroll: true,
    });
  }

  logOut() {
    this.authStore.authenticatedUser$.pipe(untilDestroyed(this), skip(1), take(1)).subscribe(() => {
      window.location.reload();
    });

    this.authStore.signOut();
  }
}
