import { Injectable } from '@angular/core';
import {
  AccountSummary,
  ApiRestService,
  RedeemedReward,
  ReferenceData,
  SearchResult,
} from '@motivforce/mx-library-angular';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Booking } from '../../model/booking';
import { BookingSearch } from '../../model/booking-search';
import { AccessStatus } from '../../model/core/access-status';
import { CommunicationsPreference } from '../../model/core/communications-preference';
import { Profile } from '../../model/core/profile';
import { RedemptionSearch } from '../../model/core/redemption-search';
import { SetPassword } from '../../model/core/set-password';
import { Transaction } from '../../model/core/transaction';
import { TransactionSearch } from '../../model/core/transaction-search';
import { LeapPerformanceSummary } from '../../model/leap-performance-summary';
import { AccountSummarySearch } from '../../model/lenovo/account-summary-search';
import { ActivateTarget } from '../../model/lenovo/activate-target';
import { CompanyTargets } from '../../model/lenovo/company-targets';
import { DistributorListsResult } from '../../model/lenovo/distributor-lists-result';
import { ElevateAccountSummary } from '../../model/lenovo/elevate-account-summary';
import { Enrolment } from '../../model/lenovo/enrolment';
import { InvoiceDetails } from '../../model/lenovo/invoice-details';
import { InvoiceDetailsQuarters } from '../../model/lenovo/invoice-details-quarters';
import { InvoiceDetailsResult } from '../../model/lenovo/invoice-details-result';
import { LenovoProfile } from '../../model/lenovo/lenovo-profile';
import { PaymentsDetails } from '../../model/lenovo/payments-details';
import { RebateSummary } from '../../model/lenovo/rebate-summary';
import { ReferralEmail } from '../../model/lenovo/referral-email';
import { Reseller } from '../../model/lenovo/reseller';
import { ResellersForDistributorResult } from '../../model/lenovo/resellers-for-distributor-result';
import { SalesTargetOption } from '../../model/lenovo/sales-target-option';
import { StarsellerEligibility } from '../../model/lenovo/starseller-eligibility';
import { UpdateDistributor } from '../../model/lenovo/update-distributor';
import { UpdateEnrollment } from '../../model/lenovo/update-enrollment';
import { UpdateTarget } from '../../model/lenovo/update-target';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private apiRest: ApiRestService) {}

  getAccountSummary(): Observable<AccountSummary> {
    return this.apiRest.get<AccountSummary>(`${environment.api.core.baseUrl}/members/users/account-summary`);
  }

  getYearAccountSummaries(): Observable<AccountSummary[]> {
    return this.apiRest.get<AccountSummary[]>(`${environment.api.core.baseUrl}/members/users/points/year-summaries`);
  }

  getLeapPerformanceSummary(): Observable<LeapPerformanceSummary> {
    return this.apiRest.get<LeapPerformanceSummary>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/users/leap-performance`
    );
  }

  getProfile(): Observable<Profile> {
    return this.apiRest.get<Profile>(`${environment.api.core.baseUrl}/members/users/profile`);
  }

  getLenovoProfile(): Observable<LenovoProfile> {
    return this.apiRest.get<LenovoProfile>(`${environment.api.core.baseUrl}/members/clients/lenovo/users/profile`);
  }

  updateRole(profileRoleName: string): Observable<any> {
    return this.apiRest.post<any>(`${environment.api.core.baseUrl}/members/clients/lenovo/users/role`, {
      profileRoleName,
    });
  }

  searchTransactions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/transactions/search`,
      searchCriteria
    );
  }

  getTransactionTypes(): Observable<ReferenceData[]> {
    return this.apiRest.get<ReferenceData[]>(`${environment.api.core.baseUrl}/members/transactions/types`);
  }

  searchPromotions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/users/promotion-transactions`,
      searchCriteria
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): Observable<SearchResult<RedeemedReward>> {
    return this.apiRest.post<SearchResult<RedeemedReward>>(
      `${environment.api.core.baseUrl}/members/redemptions/search`,
      searchCriteria
    );
  }

  searchBookings(searchCriteria: BookingSearch): Observable<SearchResult<Booking>> {
    return this.apiRest.post<SearchResult<Booking>>(
      `${environment.api.core.baseUrl}/members/clients/amber/bookings/search`,
      searchCriteria
    );
  }

  getCommunicationPreferences(): Observable<any> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovoelevate/profile/communications-preferences`
    );
  }

  updateCommunicationPreferences(form: CommunicationsPreference): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/profile/send-email`, form);
  }

  acceptTermsConditions(): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/terms-and-conditions/accept`, null);
  }

  searchAvailableVenues(name: string): Observable<ReferenceData[]> {
    return this.apiRest.post<ReferenceData[]>(
      `${environment.api.core.baseUrl}/members/clients/amber/venues/search-available`,
      { name }
    );
  }

  updateVenue(venueId: number): Observable<void> {
    return this.apiRest.put<void>(`${environment.api.core.baseUrl}/members/clients/amber/userdetails/venue`, {
      venueId,
    });
  }

  setPassword(password: SetPassword) {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/set-password`, password);
  }

  createCheckoutSession(form: any): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/payments/create-checkout-session`, form);
  }

  getRebateSummary(): Observable<RebateSummary> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/rebate-summaries/current`
    );
  }

  getSalesTargetOptions(): Observable<SalesTargetOption[]> {
    return this.apiRest.get<SalesTargetOption[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/sales-targets/options`
    );
  }

  getDistributorLists(): Observable<DistributorListsResult> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/distributors/options`
    );
  }

  submitEnrolment(enrolment: Enrolment): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/activate`,
      enrolment
    );
  }

  searchAccountSummary(searchCriteria: AccountSummarySearch): Observable<SearchResult<ElevateAccountSummary>> {
    return this.apiRest.post<SearchResult<ElevateAccountSummary>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/account-summary`,
      searchCriteria
    );
  }

  getRebateSummaries(): Observable<RebateSummary[]> {
    return this.apiRest.get<RebateSummary[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/rebate-summaries`
    );
  }

  getCompanyTargets(): Observable<CompanyTargets> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/`);
  }

  updateTarget(target: UpdateTarget): Observable<any> {
    return this.apiRest.put(`${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/`, target);
  }

  updateDistributor(distributor: UpdateDistributor): Observable<any> {
    return this.apiRest.put(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/distributors`,
      distributor
    );
  }

  updateAutoEnrollment(value: UpdateEnrollment): Observable<any> {
    return this.apiRest.put(`${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/`, value);
  }

  activateTarget(target: ActivateTarget): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/activate`,
      target
    );
  }

  getResellersForDistributor(): Observable<ResellersForDistributorResult> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/elevate/distributors/resellers`);
  }

  searchResellersForDistributor(searchCriteria: any): Observable<SearchResult<Reseller>> {
    return this.apiRest.post<SearchResult<Reseller>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/distributors/resellers/search`,
      searchCriteria
    );
  }

  submitReferralEmail(referral: ReferralEmail): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/distributors/referral`,
      referral
    );
  }

  recordReferral(referrerId: number, referredId: number): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/distributors/referral-record`,
      { referrerId, referredId }
    );
  }

  downloadCurrentQuarter(): Observable<{ content: Blob | null; fileName: string | null; contentType: string | null }> {
    return this.apiRest
      .download(
        `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/distributors/resellers/current/download`
      )
      .pipe(
        map((response) => ({
          content: response.body,
          fileName: response.headers.get('Filename'),
          contentType: response.headers.get('Content-Type'),
        }))
      );
  }

  downloadPreviousQuarter(): Observable<{ content: Blob | null; fileName: string | null; contentType: string | null }> {
    return this.apiRest
      .download(
        `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/distributors/resellers/previous/download`
      )
      .pipe(
        map((response) => ({
          content: response.body,
          fileName: response.headers.get('Filename'),
          contentType: response.headers.get('Content-Type'),
        }))
      );
  }

  getInvoicesDetailsQuarters(): Observable<InvoiceDetailsQuarters> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/invoices/quarters`
    );
  }

  getInvoicesDetails(quarter: string): Observable<InvoiceDetailsResult> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/invoices/details`,
      { quarter }
    );
  }

  uploadInvoiceFilePresignedUrl(data: any): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/invoices/presigned-url`,
      data
    );
  }

  submitInvoice(invoice: InvoiceDetails): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/invoices`,
      invoice
    );
  }

  getPaymentsDetails(): Observable<PaymentsDetails> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/payments/details`
    );
  }

  updatePaymentsDetails(payment: PaymentsDetails): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/payments/details`,
      payment
    );
  }

  getStarsellerEligibility(): Observable<StarsellerEligibility> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/star-seller/eligibility`
    );
  }

  getAccessStatus(): Observable<AccessStatus> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/elevate/companytargets/status`);
  }
}
