<ng-container *ngIf="downloadProgramGuideContent && programGuideUrl">
  <div class="w-100 d-flex wrapper">
    <div
      class="w-100 d-none d-lg-flex flex-column background"
      [ngStyle]="{
        'background-image':
          'url(' + downloadProgramGuideContent.background ? downloadProgramGuideContent.background : '' + ')'
      }"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div
      class="p-3 pt-1 w-100 d-none d-sm-flex d-lg-none flex-column background"
      [ngStyle]="{
        'background-image':
          'url(' + downloadProgramGuideContent.tabletBackground
            ? downloadProgramGuideContent.tabletBackground
            : '' + ')'
      }"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div
      class="w-100 d-flex d-sm-none flex-column background"
      [ngStyle]="{
        'background-image':
          'url(' + downloadProgramGuideContent.mobileBackground
            ? downloadProgramGuideContent.mobileBackground
            : '' + ')'
      }"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <img class="d-none d-lg-block banner" [src]="downloadProgramGuideContent.banner" />
    <img class="d-none d-sm-block d-lg-none banner" [src]="downloadProgramGuideContent.tabletBanner" />
    <img class="d-sm-none banner" [src]="downloadProgramGuideContent.mobileBanner" />
  </div>
</ng-container>

<ng-template #content>
  <a class="w-100 h-100" *ngIf="programGuideUrl" [href]="programGuideUrl" target="_blank">
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <span class="px-4 px-lg-5">{{ 'Download your programme guide' | translate }} »</span>
    </div>
  </a>
</ng-template>
