<div class="col-sm-12" *ngFor="let item of salesAccelerators">
  <div class="d-flex flex-row">
    <div class="d-flex p-3">
      <img class="sales-image" [src]="item.image" />
    </div>
    <div class="d-flex flex-column pt-3 ps-3 justify-content-center">
      <div class="title w-75 mb-3">
        <h3>{{ item.title }}</h3>
      </div>
      <div class="description w-75" [innerHtml]="item.content"></div>
    </div>
  </div>
  <div class="mx-3">
    <hr />
  </div>
</div>
