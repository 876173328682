import { Pipe, PipeTransform } from '@angular/core';
import { UserSaleGroup } from 'src/app/core/model/user/user-sale-group';

@Pipe({
  name: 'showPointsIcon',
})
export class ShowPointsIconPipe implements PipeTransform {
  transform(value: UserSaleGroup): boolean {
    let show = false;

    value.sales.forEach((sale) => {
      show = show || (sale.totalPoints !== null && sale.totalPoints > 0);
    });

    return show;
  }
}
