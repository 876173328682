<div class="container">
  <div class="row">
    <div class="col-12 wrapper">
      <div class="h-100 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <div class="banner-carousel h-100">
          <div class="h-100 p-4 d-flex flex-column justify-content-between align-items-start banner-info">
            <div class="d-flex flex-column justify-content-center align-items-start">
              <div class="mt-4 greeting">{{ 'Hi' | translate }} {{ (user$ | async)?.firstName }},</div>
              <div class="mt-2 welcome">{{ 'welcome to Elevate.' | translate }}</div>
            </div>
            <div class="mb-1">
              {{ 'Business Partner Firm: ' | translate }}
              <div class="company-name">{{ companyName }}</div>
            </div>
          </div>
          <app-carousel-slider [banners]="banners"></app-carousel-slider>
        </div>
      </div>
    </div>
  </div>
</div>
