<ng-container *ngIf="rebateSummary">
  <div class="row mt-5">
    <div class="col-12">
      <h1>{{ 'Total rebate earnings so far' | translate }}</h1>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="d-flex flex-column">
        <h1 class="points-value">{{ currencySymbol$ | async }}{{ rebateSummary.rebateDueLocalCurrency | number }}</h1>
        <h5>{{ rebateSummary.yrQtr }}</h5>
      </div>
    </div>
  </div>
  <div class="row m-0 mt-4 targets-row">
    <div class="col-12 col-sm-6">
      <app-sales-target-list [showCompletedCheck]="true"></app-sales-target-list>
    </div>

    <div class="col-12 col-sm-6">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <div class="pt-5 pt-sm-0 mx-3 mx-sm-0 w-100 d-flex justify-content-center align-items-center delimiter-box">
          <div class="points-box-wrapper">
            <label [class]="'segment-label-zero'" [id]="'segment-label-zero'">0</label>
            <label
              [class]="'segment-label-' + targetOption.band.toLowerCase()"
              [id]="'segment-label-' + i"
              *ngFor="let targetOption of salesTargetOptions; let i = index"
              >{{ 'Band Label' | translate : { band: targetOption.band } }}</label
            >
            <div class="circular-progress">
              <div
                *ngFor="let segment of progressSegments; let i = index"
                [class]="
                  'segment-' +
                  (segment === 0 ? 'inactive' : 'active') +
                  ' progress-band-' +
                  salesTargetOptions[i].band.toLowerCase()
                "
                [style]="'transform: rotate(' + (135 + i * 67.5) + 'deg) skew(22.5deg)'"
              ></div>
              <div class="inner"></div>
            </div>
            <div class="d-flex flex-column justify-content-start mt-5 align-items-center points-box">
              <div>
                <h3>{{ 'Revenue' | translate }}</h3>
              </div>
              <div class="circle-points-value">
                {{ currencySymbol$ | async }}{{ rebateSummary.qtrRevLocalCurrency | number }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
