import { Component, EventEmitter, Output } from '@angular/core';
import { Reseller } from 'src/app/core/model/lenovo/reseller';

import { CustomListComponent } from '../custom-list/custom-list.component';

@Component({
  selector: 'app-custom-distributors-search-list',
  templateUrl: './custom-distributors-search-list.component.html',
  styleUrls: ['./custom-distributors-search-list.component.scss'],
})
export class CustomDistributorsSearchListComponent extends CustomListComponent {
  @Output() inviteClick = new EventEmitter<Reseller>();

  invite(reseller: Reseller): void {
    this.inviteClick.emit(reseller);
  }
}
