import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-sign-box',
  templateUrl: './info-sign-box.component.html',
  styleUrls: ['./info-sign-box.component.scss'],
})
export class InfoSignBoxComponent {
  @Input() infoDisplayKey: string;
}
