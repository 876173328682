import { Component, OnInit } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-target-confirmation-dialog',
  templateUrl: './target-confirmation-dialog.component.html',
  styleUrls: ['./target-confirmation-dialog.component.scss'],
})
export class TargetConfirmationDialogComponent extends AbstractDialogComponent implements OnInit {
  businessUnit: string;
  contentTitle: string;
  contentDescription: string;
  footerCopyText: string;

  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    if (this.inputs && this.inputs.length > 0) {
      this.businessUnit = this.inputs[0];
      this.contentTitle = this.inputs[1];
      this.contentDescription = this.inputs[2];
      this.footerCopyText = this.inputs[3];
    }
  }

  close(): void {
    this.activeModal.close(false);
  }

  onConfirm(): void {
    this.activeModal.close(true);
  }
}
