import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-distributor-referral-page',
  templateUrl: './distributor-referral-page.component.html',
  styleUrls: ['./distributor-referral-page.component.scss'],
})
export class DistributorReferralPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private userStore: UserStoreService) {}

  ngOnInit(): void {
    const referrerId = parseInt(`${this.route.snapshot.queryParamMap.get('referrerId')}`, 10);
    const referredId = parseInt(`${this.route.snapshot.queryParamMap.get('referredId')}`, 10);

    if (referrerId > 0 && referredId > 0) {
      this.userStore.recordReferral(referrerId, referredId);
    }
  }
}
