import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, ApiRestService, MxLoggerService } from '@motivforce/mx-library-angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadRestService {
  constructor(private mxLogger: MxLoggerService, private http: HttpClient, private apiRestService: ApiRestService) {}

  uploadFileToS3(presignedUrl: string, file: File, contentType = 'text/csv', publicRead = false): Observable<any> {
    const headerDict: any = {
      'Content-Type': contentType,
    };

    if (publicRead) {
      headerDict['x-amz-acl'] = 'public-read';
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.put(presignedUrl, file, requestOptions).pipe(
      catchError((error) => {
        this.mxLogger.error('UploadRestService', 'uploadFileToS3()  error=', error);

        const apiError: ApiError = {
          name: 'server',
          message: 'There has been a server error.',
        };

        this.apiRestService.error$.next(apiError);
        return throwError(() => apiError);
      })
    );
  }
}
