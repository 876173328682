import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountSummary,
  AuthStoreService,
  LearnModulesStoreService,
  MxLoggerService,
  ReferenceData,
  SearchResult,
  DialogNgbService,
  RedeemedReward,
  ContentGqlService,
} from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';
import { IsLoadingService } from '@service-work/is-loading';
import * as fileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import { UploadRestService } from '../api/rest/upload-rest.service';
import { UserRestService } from '../api/rest/user-rest.service';
import { Booking } from '../model/booking';
import { BookingSearch } from '../model/booking-search';
import { AccessStatus } from '../model/core/access-status';
import { CommunicationsPreference } from '../model/core/communications-preference';
import { Currency } from '../model/core/currency';
import { Profile } from '../model/core/profile';
import { RedemptionSearch } from '../model/core/redemption-search';
import { Sale } from '../model/core/sale';
import { SetPassword } from '../model/core/set-password';
import { Transaction } from '../model/core/transaction';
import { TransactionSearch } from '../model/core/transaction-search';
import { LeapPerformanceSummary } from '../model/leap-performance-summary';
import { AccountSummarySearch } from '../model/lenovo/account-summary-search';
import { ActivateTarget } from '../model/lenovo/activate-target';
import { CompanyTargets } from '../model/lenovo/company-targets';
import { DistributorListsResult } from '../model/lenovo/distributor-lists-result';
import { DownloadProgramGuideContent } from '../model/lenovo/download-program-guide-content';
import { ElevateAccountSummary } from '../model/lenovo/elevate-account-summary';
import { Enrolment } from '../model/lenovo/enrolment';
import { InvoiceDetails } from '../model/lenovo/invoice-details';
import { InvoiceDetailsQuarters } from '../model/lenovo/invoice-details-quarters';
import { InvoiceDetailsResult } from '../model/lenovo/invoice-details-result';
import { LenovoProfile } from '../model/lenovo/lenovo-profile';
import { PaymentsDetails } from '../model/lenovo/payments-details';
import { RebateSummary } from '../model/lenovo/rebate-summary';
import { ReferralEmail } from '../model/lenovo/referral-email';
import { Reseller } from '../model/lenovo/reseller';
import { ResellerSearch } from '../model/lenovo/reseller-search';
import { ResellersForDistributorResult } from '../model/lenovo/resellers-for-distributor-result';
import { SalesTargetOption } from '../model/lenovo/sales-target-option';
import { StarsellerEligibility } from '../model/lenovo/starseller-eligibility';
import { UpdateDistributor } from '../model/lenovo/update-distributor';
import { UpdateTarget } from '../model/lenovo/update-target';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _accountSummary = new BehaviorSubject<AccountSummary | null>(null);
  readonly accountSummary$ = this._accountSummary.asObservable();

  private readonly _yearAccountSummaries = new BehaviorSubject<AccountSummary[] | null>(null);
  readonly yearAccountSummaries$ = this._yearAccountSummaries.asObservable();

  private readonly _leapPerformanceSummary = new BehaviorSubject<LeapPerformanceSummary | null>(null);
  readonly leapPerformanceSummary$ = this._leapPerformanceSummary.asObservable();

  private readonly _profile = new BehaviorSubject<Profile | null>(null);
  readonly profile$ = this._profile.asObservable();

  private readonly _lenovoProfile = new BehaviorSubject<LenovoProfile | null>(null);
  readonly lenovoProfile$ = this._lenovoProfile.asObservable();

  private readonly _currentTransactionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentTransactionResults$ = this._currentTransactionResults.asObservable();

  private readonly _currentPromotionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentPromotionResults$ = this._currentPromotionResults.asObservable();

  private readonly _currentRedeemedRewardResults = new BehaviorSubject<SearchResult<RedeemedReward> | null>(null);
  readonly currentRedeemedRewardResults$ = this._currentRedeemedRewardResults.asObservable();

  private readonly _currentBookingsResults = new BehaviorSubject<SearchResult<any> | null>(null);
  readonly currentBookingsResults$ = this._currentBookingsResults.asObservable();

  private readonly _transactionTypes = new BehaviorSubject<ReferenceData[] | null>(null);
  readonly transactionTypes$ = this._transactionTypes.asObservable();

  private readonly _typeaheadVenues = new BehaviorSubject<any[] | null>(null);
  readonly typeaheadVenues$ = this._typeaheadVenues.asObservable();

  private readonly _paymentIntent = new BehaviorSubject<any | null>(null);
  readonly paymentIntent$ = this._paymentIntent.asObservable();

  private readonly _communicationPreferences = new BehaviorSubject<CommunicationsPreference | null>(null);
  readonly communicationPreferences$ = this._communicationPreferences.asObservable();

  private readonly _rebateSummary = new BehaviorSubject<RebateSummary | null>(null);
  readonly rebateSummary$ = this._rebateSummary.asObservable();

  private readonly _salesTargetOptions = new BehaviorSubject<SalesTargetOption[] | null>(null);
  readonly salesTargetOptions$ = this._salesTargetOptions.asObservable();

  private readonly _distributorListsResult = new BehaviorSubject<DistributorListsResult | null>(null);
  readonly distributorListsResult$ = this._distributorListsResult.asObservable();

  private readonly _currentAccountSummaryResults = new BehaviorSubject<SearchResult<ElevateAccountSummary> | null>(
    null
  );

  readonly currentAccountSummaryResults$ = this._currentAccountSummaryResults.asObservable();

  private readonly _rebateSummaries = new BehaviorSubject<RebateSummary[] | null>(null);

  readonly rebateSummaries$ = this._rebateSummaries.asObservable();

  private readonly _companyTargets = new BehaviorSubject<CompanyTargets | null>(null);
  readonly companyTargets$ = this._companyTargets.asObservable();

  private readonly _resellers = new BehaviorSubject<ResellersForDistributorResult | null>(null);
  readonly resellers$ = this._resellers.asObservable();

  private readonly _resellerSearchResult = new BehaviorSubject<SearchResult<Reseller> | null>(null);
  readonly resellerSearchResult$ = this._resellerSearchResult.asObservable();

  private readonly _invoiceDetailsQuarters = new BehaviorSubject<InvoiceDetailsQuarters | null>(null);
  readonly invoiceDetailsQuarters$ = this._invoiceDetailsQuarters.asObservable();

  private readonly _currentInvoiceDetails = new BehaviorSubject<InvoiceDetailsResult | null>(null);
  readonly currentInvoiceDetails$ = this._currentInvoiceDetails.asObservable();

  private readonly _paymentsDetails = new BehaviorSubject<PaymentsDetails | null>(null);
  readonly paymentsDetails$ = this._paymentsDetails.asObservable();

  private readonly _starsellerEligibility = new BehaviorSubject<StarsellerEligibility | null>(null);
  readonly starsellerEligibility$ = this._starsellerEligibility.asObservable();

  private readonly _downloadProgramGuideContent = new BehaviorSubject<DownloadProgramGuideContent | null>(null);
  readonly downloadProgramGuideContent$ = this._downloadProgramGuideContent.asObservable();

  private readonly _currency = new BehaviorSubject<Currency | null>(null);
  readonly currency$ = this._currency.asObservable();

  private readonly _accessStatus = new BehaviorSubject<AccessStatus | null>(null);
  readonly accessStatus$ = this._accessStatus.asObservable();

  constructor(
    private userRest: UserRestService,
    private uploadRest: UploadRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
    private learnModuleStore: LearnModulesStoreService,
    private mxLogger: MxLoggerService,
    private authStore: AuthStoreService,
    private translateService: TranslateService,
    private router: Router,
    private contentGql: ContentGqlService
  ) {
    this.learnModuleStore.currentLearnModule$
      .pipe(pairwise())
      .subscribe(([previousLearnModule, currentLearnModule]) => {
        if (
          previousLearnModule &&
          previousLearnModule.id === currentLearnModule!.id &&
          !previousLearnModule.pointsAwarded &&
          currentLearnModule!.pointsAwarded
        ) {
          this.mxLogger.info('UserStoreService', 'Current Learn Module points awarded. Get user account summary.');
          this.getAccountSummary();
        }
      });
  }

  get accountSummary(): AccountSummary | null {
    return this._accountSummary.getValue();
  }

  get yearAccountSummaries(): AccountSummary[] | null {
    return this._yearAccountSummaries.getValue();
  }

  get profile(): Profile | null {
    return this._profile.getValue();
  }

  get lenovoProfile(): LenovoProfile | null {
    return this._lenovoProfile.getValue();
  }

  get currentTransactionResults(): SearchResult<Transaction> | null {
    return this._currentTransactionResults.getValue();
  }

  get currentPromotionResults(): SearchResult<Transaction> | null {
    return this._currentPromotionResults.getValue();
  }

  get currentRedeemedRewardResults(): SearchResult<RedeemedReward> | null {
    return this._currentRedeemedRewardResults.getValue();
  }

  get currentBookingsResults(): SearchResult<Sale> | null {
    return this._currentBookingsResults.getValue();
  }

  get transactionTypes(): ReferenceData[] | null {
    return this._transactionTypes.getValue();
  }

  get typeaheadVenues(): any[] | null {
    return this._typeaheadVenues.value;
  }

  get communicationPreferences(): CommunicationsPreference | null {
    return this._communicationPreferences.getValue();
  }

  get rebateSummary(): RebateSummary | null {
    return this._rebateSummary.getValue();
  }

  get salesTargetOptions(): SalesTargetOption[] | null {
    return this._salesTargetOptions.getValue();
  }

  get distributorListsResult(): DistributorListsResult | null {
    return this._distributorListsResult.getValue();
  }

  get accountSummaryResults(): SearchResult<ElevateAccountSummary> | null {
    return this._currentAccountSummaryResults.getValue();
  }

  get rebateSummaries(): RebateSummary[] | null {
    return this._rebateSummaries.getValue();
  }

  get companyTargets(): CompanyTargets | null {
    return this._companyTargets.getValue();
  }

  get resellers(): ResellersForDistributorResult | null {
    return this._resellers.getValue();
  }

  get resellerSearchResult(): SearchResult<Reseller> | null {
    return this._resellerSearchResult.getValue();
  }

  get invoiceDetailsQuarters(): InvoiceDetailsQuarters | null {
    return this._invoiceDetailsQuarters.getValue();
  }

  get currentInvoiceDetails(): InvoiceDetailsResult | null {
    return this._currentInvoiceDetails.getValue();
  }

  get paymentsDetails(): PaymentsDetails | null {
    return this._paymentsDetails.getValue();
  }

  get starsellerEligibility(): StarsellerEligibility | null {
    return this._starsellerEligibility.getValue();
  }

  get downloadProgramGuideContent(): DownloadProgramGuideContent | null {
    return this._downloadProgramGuideContent.getValue();
  }

  get currency(): Currency | null {
    return this._currency.getValue();
  }

  get accessStatus(): AccessStatus | null {
    return this._accessStatus.getValue();
  }

  getAccountSummary(): void {
    this.isLoadingService.add(
      this.userRest.getAccountSummary().subscribe((accountSummary: AccountSummary) => {
        this._accountSummary.next(accountSummary);
      })
    );
  }

  getYearAccountSummaries(): void {
    this.isLoadingService.add(
      this.userRest.getYearAccountSummaries().subscribe((yearAccountSummaries: AccountSummary[]) => {
        this._yearAccountSummaries.next(yearAccountSummaries);
      })
    );
  }

  getLeapPerformanceSummary(): void {
    this.isLoadingService.add(
      this.userRest.getLeapPerformanceSummary().subscribe((summary) => {
        this._leapPerformanceSummary.next(summary);
      })
    );
  }

  getProfile(): void {
    this.isLoadingService.add(
      this.userRest.getProfile().subscribe((profile: Profile) => {
        this._profile.next(profile);
      })
    );
  }

  getLenovoProfile(): void {
    this.isLoadingService.add(
      this.userRest.getLenovoProfile().subscribe((lenovoProfile: LenovoProfile) => {
        this._lenovoProfile.next(lenovoProfile);
      })
    );
  }

  updateRole(profileRoleName: string): void {
    this.isLoadingService.add(
      this.userRest.updateRole(profileRoleName).subscribe(() => {
        this.dialog.openNotification(['You have updated your role successfully.'], '');
      })
    );
  }

  searchTransactions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchTransactions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentTransactionResults.next(searchResult);
      })
    );
  }

  getTransactionTypes(): void {
    if (this.transactionTypes) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getTransactionTypes().subscribe((transactionTypes: ReferenceData[]) => {
        this._transactionTypes.next(transactionTypes);
      })
    );
  }

  searchPromotions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchPromotions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentPromotionResults.next(searchResult);
      })
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchRedeemedRewards(searchCriteria).subscribe((searchResult: SearchResult<RedeemedReward>) => {
        this._currentRedeemedRewardResults.next(searchResult);
      })
    );
  }

  searchBookings(searchCriteria: BookingSearch): void {
    this.isLoadingService.add(
      this.userRest.searchBookings(searchCriteria).subscribe((searchResult: SearchResult<Booking>) => {
        this._currentBookingsResults.next(searchResult);
      })
    );
  }

  getCommunicationPreferences(): void {
    this.isLoadingService.add(
      this.userRest.getCommunicationPreferences().subscribe((communicationPreferences: CommunicationsPreference) => {
        this._communicationPreferences.next(communicationPreferences);
      })
    );
  }

  updateCommunicationPreferences(communicationPreferences: CommunicationsPreference): void {
    this.isLoadingService.add(
      this.userRest.updateCommunicationPreferences(communicationPreferences).subscribe(() => {
        this.dialog.openNotification(['You have updated your communication preferences.'], '');
      })
    );
  }

  acceptTermsConditions(): void {
    this.isLoadingService.add(
      this.userRest.acceptTermsConditions().subscribe(() => {
        this.authStore.getUserSettings();
      })
    );
  }

  typeaheadUserSearchVenue(venueSearch: any): void {
    this.isLoadingService.add(
      this.userRest.searchAvailableVenues(venueSearch).subscribe((venues: any[]) => this._typeaheadVenues.next(venues))
    );
  }

  updateVenue(venueId: any): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.updateVenue(venueId).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  setPassword(password: SetPassword): void {
    this.isLoadingService.add(
      this.userRest.setPassword(password).subscribe(() => {
        this.dialog.openNotification(['You have updated your password successfully.'], '');
        this.router.navigate(['/auth/login']);
      })
    );
  }

  createCheckoutSession(form: any): void {
    this.isLoadingService.add(
      this.userRest.createCheckoutSession(form).subscribe((paymentIntent) => {
        this._paymentIntent.next(paymentIntent);
      })
    );
  }

  getRebateSummary(): void {
    if (this.rebateSummary) return;

    this.isLoadingService.add(
      this.userRest.getRebateSummary().subscribe((rebateSummary) => {
        this._rebateSummary.next(rebateSummary);

        if (rebateSummary) {
          this._currency.next({
            symbol: rebateSummary.targetCurrencySymbol ?? '',
            isoCode: rebateSummary.targetCurrencyIsoCode ?? '',
          });
        }
      })
    );
  }

  getSalesTargetOptions(): void {
    if (this.salesTargetOptions) return;

    this.isLoadingService.add(
      this.userRest.getSalesTargetOptions().subscribe((salesTargetOptions) => {
        this._salesTargetOptions.next(salesTargetOptions.sort((a, b) => a.salesTarget - b.salesTarget));
      })
    );
  }

  getDistributorLists(): void {
    if (this.distributorListsResult) return;

    this.isLoadingService.add(
      this.userRest.getDistributorLists().subscribe((distributorLists: DistributorListsResult) => {
        this._distributorListsResult.next(distributorLists);
      })
    );
  }

  submitEnrolment(enrollment: Enrolment): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.submitEnrolment(enrollment).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  searchAccountSummary(searchCriteria: AccountSummarySearch): void {
    this.isLoadingService.add(
      this.userRest
        .searchAccountSummary(searchCriteria)
        .subscribe((searchResult: SearchResult<ElevateAccountSummary>) => {
          this._currentAccountSummaryResults.next(searchResult);
        })
    );
  }

  getRebateSummaries(): void {
    if (this.rebateSummaries) return;

    this.isLoadingService.add(
      this.userRest.getRebateSummaries().subscribe((rebateSummaries) => {
        this._rebateSummaries.next(rebateSummaries);
      })
    );
  }

  getCompanyTargets(): void {
    this.isLoadingService.add(
      this.userRest.getCompanyTargets().subscribe((companyTargets: CompanyTargets) => {
        this._companyTargets.next(companyTargets);
      })
    );
  }

  updateTarget(target: UpdateTarget): void {
    this.isLoadingService.add(
      this.userRest.updateTarget(target).subscribe(() => {
        this.dialog.openNotification(
          [
            this.translateService.instant('Thank you, your {{type}} target option has been successfully updated.', {
              type: target.isPcsd ? 'PCSD' : 'ISG',
            }),
          ],
          ''
        );
        this.getStarsellerEligibility(true);
      })
    );
  }

  activateTarget(target: ActivateTarget): void {
    this.isLoadingService.add(
      this.userRest.activateTarget(target).subscribe(() => {
        this.dialog.openNotification([this.translateService.instant('You have activated your target.')], '');
        this.getStarsellerEligibility(true);
      })
    );
  }

  getResellersForDistributor(): void {
    if (this.resellers) return;

    this.isLoadingService.add(
      this.userRest.getResellersForDistributor().subscribe((resellers: ResellersForDistributorResult) => {
        this._resellers.next(resellers);
      })
    );
  }

  searchResellersForDistributor(searchCriteria: ResellerSearch): void {
    this.isLoadingService.add(
      this.userRest.searchResellersForDistributor(searchCriteria).subscribe((resellers: SearchResult<Reseller>) => {
        this._resellerSearchResult.next(resellers);
      })
    );
  }

  submitReferralEmail(data: ReferralEmail): void {
    this.isLoadingService.add(
      this.userRest.submitReferralEmail(data).subscribe(() => {
        this.dialog.openNotification([this.translateService.instant('ElevateDistributorHomeSendReferralThankYou')], '');
      })
    );
  }

  recordReferral(referrerId: number, referredId: number): void {
    this.isLoadingService.add(
      this.userRest.recordReferral(referrerId, referredId).subscribe(() => {
        this.router.navigate(['/home']);
      })
    );
  }

  downloadCurrentQuarter(): void {
    this.isLoadingService.add(
      this.userRest.downloadCurrentQuarter().subscribe((result) => {
        if (result.content && result.contentType) {
          const blob: any = new Blob([result.content], { type: result.contentType });
          fileSaver.saveAs(blob, result.fileName ?? 'download.xlsx');
        }
      })
    );
  }

  downloadPreviousQuarter(): void {
    this.isLoadingService.add(
      this.userRest.downloadPreviousQuarter().subscribe((result) => {
        if (result.content && result.contentType) {
          const blob: any = new Blob([result.content], { type: result.contentType });
          fileSaver.saveAs(blob, result.fileName ?? 'download.xlsx');
        }
      })
    );
  }

  getInvoicesDetailsQuarters(): void {
    if (this.invoiceDetailsQuarters) return;

    this.isLoadingService.add(
      this.userRest.getInvoicesDetailsQuarters().subscribe((invoicesDetailsQuarters: InvoiceDetailsQuarters) => {
        this._invoiceDetailsQuarters.next(invoicesDetailsQuarters);
      })
    );
  }

  getInvoicesDetails(quarter: string): void {
    this.isLoadingService.add(
      this.userRest.getInvoicesDetails(quarter).subscribe((invoicesDetails: InvoiceDetailsResult) => {
        this._currentInvoiceDetails.next(invoicesDetails);
      })
    );
  }

  uploadInvoiceFile(details: InvoiceDetails, file: File): Promise<string | null> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest
          .uploadInvoiceFilePresignedUrl({
            fileName: file.name,
            fileContentType: file.type,
            quarter: details.quarter,
            invoiceNumber: details.invoiceNumber,
          })
          .subscribe({
            next: (presignedUrl) =>
              this.isLoadingService.add(
                this.uploadRest.uploadFileToS3(presignedUrl.url, file, file.type, true).subscribe({
                  next: () => {
                    resolve(presignedUrl.fileName);
                  },
                  error: () => {
                    resolve(null);
                  },
                })
              ),
            error: () => {
              resolve(null);
            },
          })
      );
    });
  }

  submitInvoice(invoice: InvoiceDetails): void {
    this.isLoadingService.add(
      this.userRest.submitInvoice(invoice).subscribe(() => {
        this.dialog.openNotification([this.translateService.instant('You have submitted your invoice.')], '');
        this.getInvoicesDetailsQuarters();
        this.getPaymentsDetails();
      })
    );
  }

  getPaymentsDetails(): void {
    this.isLoadingService.add(
      this.userRest.getPaymentsDetails().subscribe((paymentsDetails: PaymentsDetails) => {
        this._paymentsDetails.next(paymentsDetails);
      })
    );
  }

  updatePaymentDetails(paymentDetails: PaymentsDetails): void {
    this.isLoadingService.add(
      this.userRest.updatePaymentsDetails(paymentDetails).subscribe((responsePaymentsDetails: PaymentsDetails) => {
        this.dialog.openNotification([this.translateService.instant('Your bank details have been updated.')], '');
        this._paymentsDetails.next(responsePaymentsDetails);
      })
    );
  }

  getStarsellerEligibility(force = false): void {
    if (this.starsellerEligibility && !force) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getStarsellerEligibility().subscribe((starsellerEligibility) => {
        this._starsellerEligibility.next(starsellerEligibility);
      })
    );
  }

  getDownloadProgramGuideContent(): void {
    if (this.downloadProgramGuideContent) {
      return;
    }

    this.isLoadingService.add(
      this.contentGql
        .getContentByUrlPath(
          `${window.location.protocol}//${window.location.host}/download-program-guide-component`,
          'en-GB',
          true
        )
        .subscribe((baseContent) => {
          const bannerField = baseContent.fields.find((f: any) => f.name === 'Banner');
          const tabletBannerField = baseContent.fields.find((f: any) => f.name === 'Tablet Banner');
          const mobileBannerField = baseContent.fields.find((f: any) => f.name === 'Mobile Banner');
          const backgroundField = baseContent.fields.find((f: any) => f.name === 'Background');
          const tabletBackgroundField = baseContent.fields.find((f: any) => f.name === 'Tablet Background');
          const mobileBackgroundField = baseContent.fields.find((f: any) => f.name === 'Mobile Background');
          const idgIsgProgramGuideUrlListField = baseContent.fields.find(
            (f: any) => f.name === 'IDG + ISG Program Guide Url List'
          );
          const idgProgramGuideUrlListField = baseContent.fields.find(
            (f: any) => f.name === 'IDG Program Guide Url List'
          );
          const isgProgramGuideUrlListField = baseContent.fields.find(
            (f: any) => f.name === 'ISG Program Guide Url List'
          );

          const banner = bannerField!.value.url;
          const tabletBanner = tabletBannerField!.value.url;
          const mobileBanner = mobileBannerField!.value.url;
          const background = backgroundField!.value.url;
          const tabletBackground = tabletBackgroundField!.value.url;
          const mobileBackground = mobileBackgroundField!.value.url;
          const idgIsgProgramGuideUrlListId = idgIsgProgramGuideUrlListField!.value.value;
          const idgProgramGuideUrlListId = idgProgramGuideUrlListField!.value.value;
          const isgProgramGuideUrlListId = isgProgramGuideUrlListField!.value.value;

          this._downloadProgramGuideContent.next({
            banner,
            tabletBanner,
            mobileBanner,
            background,
            tabletBackground,
            mobileBackground,
            idgIsgProgramGuideUrlListId,
            idgProgramGuideUrlListId,
            isgProgramGuideUrlListId,
          });
        })
    );
  }

  updateDistributor(value: UpdateDistributor): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.updateDistributor(value).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  updateAutoEnrollment(value: any): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.updateAutoEnrollment(value).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  getAccessStatus(): void {
    this.isLoadingService.add(
      this.userRest.getAccessStatus().subscribe((accessStatus) => {
        this._accessStatus.next(accessStatus);
      })
    );
  }
}
