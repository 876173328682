<p *ngIf="!details?.invoiceNumber && !details?.isRebateDue">
  {{ 'You are not due a rebate from previous quarter' | translate }}
</p>
<form [formGroup]="form" *ngIf="!details?.invoiceNumber && details?.isRebateDue" (ngSubmit)="onSubmit()">
  <div class="form-group d-flex flex-row align-items-start mb-4">
    <label for="invoiceNumber" class="w-25">{{ 'Invoice number' | translate }}</label>
    <input type="text" class="form-control w-50" id="invoiceNumber" formControlName="invoiceNumber" />
  </div>
  <div class="form-group d-flex flex-row align-items-start mb-4">
    <label for="invoiceDate" class="w-25">{{ 'Invoice date' | translate }}</label>
    <lib-datepicker-ngb-form-control
      formControlName="invoiceDate"
      [minDate]="null"
      [maxDate]="null"
      [inputClass]="'datepicker-input form-control'"
      [containerClass]="'datepicker-container d-flex flex-row align-items-center mb-1'"
      [faIconClass]="'datepicker-icon ms-1'"
      class="w-50"
    ></lib-datepicker-ngb-form-control>
  </div>
  <div class="form-group d-flex flex-row align-items-start mb-4">
    <label for="localCurrency" class="w-25">{{ 'Local currency' | translate }}</label>
    <input type="text" class="form-control w-50" id="localCurrency" formControlName="localCurrency" />
  </div>
  <div class="form-group d-flex flex-row align-items-start mb-4">
    <label for="rebateAmountInLocalCurrency" class="w-25">{{ 'Rebate amount in local currency' | translate }}</label>
    <input
      type="text"
      class="form-control w-50"
      id="rebateAmountInLocalCurrency"
      formControlName="rebateAmountInLocalCurrency"
    />
  </div>
  <div class="form-group mb-4">
    <label for="invoiceFile" class="btn btn-primary">{{ 'Attach file' | translate }}</label>
    <input
      type="file"
      class="form-control-file d-none"
      id="invoiceFile"
      formControlName="invoiceFile"
      (change)="onFileSelected($event)"
    />
    <span class="ms-3"> {{ selectedFile?.name || 'No file selected.' | translate }}</span>
  </div>
  <div class="form-group mb-4">
    <button type="submit" class="btn btn-primary">{{ 'Upload invoice' | translate }}</button>
  </div>
</form>
