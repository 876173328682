import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  AuthStoreService,
  DialogNgbService,
  MxLoggerService,
  Content,
  ContentDynamicLayout,
} from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, skip, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-login-content-template',
  templateUrl: './login-content-template.component.html',
  styleUrls: ['./login-content-template.component.scss'],
})
export class LoginContentTemplateComponent implements OnInit, ContentDynamicLayout {
  @Input() baseContent: Content;
  background: string;

  formGroup: UntypedFormGroup = this.formBuilder.group({
    email: [null, [Validators.required]],
    password: [null, [Validators.required]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authStore: AuthStoreService,
    private dialogService: DialogNgbService,
    private mxLogger: MxLoggerService
  ) {}

  ngOnInit(): void {
    const backgroundField = this.baseContent.fields.find((f) => f.name === 'Background');

    this.background = backgroundField!.value.url;

    this.authStore.authenticatedUser$
      .pipe(untilDestroyed(this), skip(1), filter(Boolean))
      .subscribe((authenticatedUser) => {
        this.mxLogger.trace('LoginContainer', `NEW authenticatedUser=`, authenticatedUser);
        this.router.navigate(['/home']);
      });
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      const formData = this.formGroup.getRawValue();

      this.authStore.authenticatedUser$.pipe(skip(1), take(1)).subscribe((authenticatedUser) => {
        this.mxLogger.trace('LoginContainer', ' NEW authenticatedUser=', authenticatedUser);
        this.router.navigate(['/home']);
      });

      this.authStore.signIn(formData.email, formData.password);
    } else {
      this.mxLogger.trace('LoginContainer', 'Form is still invalid.');
      this.dialogService.openError(['Please input a valid email address and password.'], 'Important');
    }
  }

  signInWithGoogle() {
    this.authStore.federatedSignIn(CognitoHostedUIIdentityProvider.Google);
  }
}
