import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { User } from '../model/user';
import { UserStoreService } from '../store/user-store.service';

function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(private authStore: AuthStoreService, private userStore: UserStoreService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authStore.isRegularUser$.pipe(
      filter(isNonNull),
      switchMap((isRegularUser: boolean) => {
        if (isRegularUser) {
          return this.userStore.accessStatus$.pipe(
            filter(Boolean),
            map(() => {
              if (this.isBlockedUser()) {
                return this.router.parseUrl('/not-allowed');
              }

              if (this.userIsNotEnrolled() && state.url !== '/enrolment') {
                return this.router.parseUrl('/enrolment');
              }

              return true;
            })
          );
        }

        return of(true);
      })
    );
  }

  private isBlockedUser(): boolean {
    return (
      !!this.userStore.accessStatus &&
      (this.userStore.accessStatus.userEnrollingInFinalMonth ||
        this.userStore.accessStatus.userIsFromBlockedCountry ||
        this.userStore.accessStatus.companyInExcludedList)
    );
  }

  private userIsNotEnrolled(): boolean {
    return (this.authStore.userSettings?.user as User)?.acceptedTermsConditions === false;
  }
}
