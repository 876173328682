import { Pipe, PipeTransform } from '@angular/core';
import { UserSaleGroup } from 'src/app/core/model/user/user-sale-group';

@Pipe({
  name: 'showRevenueIcon',
})
export class ShowRevenueIconPipe implements PipeTransform {
  transform(value: UserSaleGroup): boolean {
    let show = false;

    value.sales.forEach((sale) => {
      show = show || (sale.serviceRevenue !== null && sale.serviceRevenue > 0);
    });

    return show;
  }
}
