<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [serverPaging]="true"
  [defaultPagination]="false"
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  [rowHeight]="rowHeight"
  [selectable]="true"
  [allSelectable]="true"
  [expandableRow]="true"
  [defaultRowClick]="false"
  [itemSelectableFunction]="itemSelectableFunction"
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'invoiceNumber'; let row">
    <div class="d-flex align-items-center">
      <button class="px-0 mt-1 mt-lg-0 btn btn-link" (click)="genericList.openRowDetails(row)">
        {{ row.invoiceNumber }}
      </button>
    </div>
  </ng-container>
  <ng-container *libGenericListCustomItemRenderer="'detail'; let row">
    <div class="d-flex align-items-center" [ngStyle]="{ height: rowHeight }">
      <a [routerLink]="'/promotions'" *ngIf="row | showRevenueIcon">
        <img class="icon" src="assets/image/LUN2183_Revenue.svg" />
      </a>
      <img *ngIf="row | showPointsIcon" class="ms-2 icon" src="assets/image/LUN2183_Points.svg" />
      <img *ngIf="row | showCreditsIcon" class="ms-1 icon" src="assets/image/LUN2183_Credits.svg" />
    </div>
  </ng-container>
  <ng-container *libGenericListRowDetailsRenderer="''; let row">
    <div class="p-3 d-flex d-sm-none flex-column">
      <ng-container *ngFor="let sale of row.sales">
        <div class="my-2 d-flex flex-column line">
          <div class="d-flex flex-column">
            <strong>{{ 'Product' | translate }}</strong>
            <div>{{ sale.productCode }}</div>
          </div>
          <div class="my-2 d-flex flex-column">
            <strong>{{ 'Quantity' | translate }}</strong>
            <div>{{ sale.quantity }}</div>
          </div>
          <div class="my-2 d-flex flex-column">
            <strong>{{ 'LEAP Award' | translate }}</strong>
            <div class="d-flex icons-wrapper">
              <div class="d-flex align-items-center" *ngIf="sale.serviceRevenue > 0">
                <img class="icon" src="assets/image/LUN2183_Revenue.svg" />
                <span class="ms-1">${{ sale.serviceRevenue | currency }}</span>
              </div>
              <div class="d-flex align-items-center" *ngIf="sale.totalPoints > 0">
                <img class="icon" src="assets/image/LUN2183_Points.svg" />
                <span class="ms-1">{{ sale.totalPoints }}</span>
              </div>
              <div class="d-flex align-items-center" *ngIf="sale.credit > 0">
                <img class="icon" src="assets/image/LUN2183_Credits.svg" />
                <span class="ms-1">{{ sale.credit }}</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-2" />
      </ng-container>
    </div>
    <div class="d-none d-sm-flex flex-column row-details">
      <div class="d-flex my-2 line">
        <div>
          <strong>{{ 'Product' | translate }}</strong>
        </div>
        <div>
          <strong>{{ 'Quantity' | translate }}</strong>
        </div>
        <div>
          <strong>{{ 'LEAP Award' | translate }}</strong>
        </div>
      </div>
      <ng-container *ngFor="let sale of row.sales">
        <hr class="my-2" />
        <div class="d-flex line">
          <div>{{ sale.productCode }}</div>
          <div>{{ sale.quantity }}</div>
          <div class="d-flex icons-wrapper">
            <div class="d-flex align-items-center" *ngIf="sale.serviceRevenue > 0">
              <img class="icon" src="assets/image/LUN2183_Revenue.svg" />
              <span class="ms-1">${{ sale.serviceRevenue | currency }}</span>
            </div>
            <div class="d-flex align-items-center" *ngIf="sale.totalPoints > 0">
              <img class="icon" src="assets/image/LUN2183_Points.svg" />
              <span class="ms-1">{{ sale.totalPoints }}</span>
            </div>
            <div class="d-flex align-items-center" *ngIf="sale.credit > 0">
              <img class="icon" src="assets/image/LUN2183_Credits.svg" />
              <span class="ms-1">{{ sale.credit }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</lib-generic-list>
