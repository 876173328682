import { Input, Component } from '@angular/core';
import { SalesAccelerator } from 'src/app/core/model/core/sales-accelerator';

@Component({
  selector: 'app-sales-accelerator-list',
  templateUrl: './sales-accelerator-list.component.html',
  styleUrls: ['./sales-accelerator-list.component.scss'],
})
export class SalesAcceleratorListComponent {
  @Input() salesAccelerators: SalesAccelerator[] = [];
}
