import { Component, Input, OnInit } from '@angular/core';
import { ContentStoreService, NavMenu } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-footer-top-nav',
  templateUrl: './footer-top-nav.component.html',
  styleUrls: ['./footer-top-nav.component.scss'],
})
export class FooterTopNavComponent implements OnInit {
  @Input() loggedIn = false;

  year: string;

  footerMenuHelp: NavMenu;
  footerMenuAbout: NavMenu;
  footerMenuPartners: NavMenu;

  constructor(private contentStore: ContentStoreService) {}

  ngOnInit(): void {
    this.contentStore.footerMenus$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((footerMenus) => {
      this.footerMenuHelp = footerMenus[0];
      this.footerMenuAbout = footerMenus[1];
      this.footerMenuPartners = footerMenus[2];
    });
  }
}
