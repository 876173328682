import { Component, OnInit } from '@angular/core';
import { AuthStoreService, ContentStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { Currency } from 'src/app/core/model/core/currency';
import { DownloadProgramGuideContent } from 'src/app/core/model/lenovo/download-program-guide-content';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-download-program-guide',
  templateUrl: './download-program-guide.component.html',
  styleUrls: ['./download-program-guide.component.scss'],
})
export class DownloadProgramGuideComponent implements OnInit {
  downloadProgramGuideContent: DownloadProgramGuideContent;
  programGuideUrl: string;
  isoCodeProgramGuideUrlMap: any;
  currency: Currency;

  constructor(
    private authStore: AuthStoreService,
    private userStore: UserStoreService,
    private contentStore: ContentStoreService
  ) {}

  ngOnInit(): void {
    this.contentStore.currentContent$
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        filter(
          (content) =>
            content.id === this.downloadProgramGuideContent?.isgProgramGuideUrlListId ||
            content.id === this.downloadProgramGuideContent?.idgProgramGuideUrlListId ||
            content.id === this.downloadProgramGuideContent?.idgIsgProgramGuideUrlListId
        )
      )
      .subscribe((content) => {
        if (content.children) {
          this.isoCodeProgramGuideUrlMap = {};

          content.children.forEach((item) => {
            const itemField = item.fields.find((f) => f.name === 'URL');
            this.isoCodeProgramGuideUrlMap[item.name] = itemField!.value.value;
          });
        }

        this.setProgramGuideUrl();
      });

    this.userStore.currency$.pipe(untilDestroyed(this), filter(Boolean)).subscribe(() => {
      this.setProgramGuideUrl();
    });

    this.userStore.downloadProgramGuideContent$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((content) => {
      this.downloadProgramGuideContent = content;

      const companies = (this.authStore.userSettings as any)?.user?.companies;

      if (companies && companies.length > 0) {
        if (companies[0].elevateCompanyDetails?.isIsg && !companies[0].elevateCompanyDetails?.isPcsd) {
          this.contentStore.getContentById(this.downloadProgramGuideContent.isgProgramGuideUrlListId, true);
        } else if (!companies[0].elevateCompanyDetails?.isIsg && companies[0].elevateCompanyDetails?.isPcsd) {
          this.contentStore.getContentById(this.downloadProgramGuideContent.idgProgramGuideUrlListId, true);
        } else {
          this.contentStore.getContentById(this.downloadProgramGuideContent.idgIsgProgramGuideUrlListId, true);
        }

        this.currency = companies[0]?.elevateCompanyDetails?.currency;
      }
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((settings: any) => {
      const canImpersonate = settings.claims.find((c: string) => c === 'user.impersonate');

      if (!canImpersonate) {
        this.userStore.getDownloadProgramGuideContent();
      }
    });
  }

  private setProgramGuideUrl(): void {
    if (this.isoCodeProgramGuideUrlMap && this.currency) {
      this.programGuideUrl = this.isoCodeProgramGuideUrlMap[this.currency.isoCode];
    }
  }
}
