import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStoreService, MxLoggerService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'src/app/core/model/user';

@UntilDestroy()
@Component({
  selector: 'app-login-redirect-container',
  templateUrl: './login-redirect-container.component.html',
  styleUrls: ['./login-redirect-container.component.scss'],
})
export class LoginRedirectContainerComponent implements OnInit {
  constructor(private authStore: AuthStoreService, private mxLogger: MxLoggerService, private router: Router) {}

  ngOnInit(): void {
    this.authStore.authenticatedUser$.pipe(untilDestroyed(this)).subscribe((authenticatedUser) => {
      this.mxLogger.trace('LoginRedirectContainerComponent', 'ngOnInit() NEW authenticatedUser=', authenticatedUser);
      if (authenticatedUser) {
        if (this.isDistributor()) {
          this.router.navigate(['/distributors']);
        } else {
          this.router.navigate(['/home']);
        }
      }
    });

    this.authStore.getCurrentAuthenticatedUser();
  }

  private isDistributor() {
    const userRole = (this.authStore.userSettings?.user as User)?.userRole;
    return userRole?.name === 'lenovo.elevate.disti.access';
  }
}
