import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpTokenInterceptor } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';

@Injectable()
export class CustomHttpTokenInterceptor extends HttpTokenInterceptor {
  override intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.match(/referral-record/gi)) {
      return super.intercept(request, next);
    }

    return next.handle(request);
  }
}
