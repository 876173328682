import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { CustomListComponent } from '../custom-list/custom-list.component';

@UntilDestroy()
@Component({
  selector: 'app-custom-transactions-list',
  templateUrl: './custom-transactions-list.component.html',
  styleUrls: ['./custom-transactions-list.component.scss'],
})
export class CustomTransactionsListComponent extends CustomListComponent implements OnInit {
  programDateFormat: string;

  override itemsPerPageDisplayKey = `${this.translate.instant('Items per page')}:`;
  override entriesDisplayKey = '';

  constructor(protected override translate: TranslateService, private authStore: AuthStoreService) {
    super(translate);
  }

  ngOnInit() {
    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((userSettings) => {
      this.programDateFormat = (userSettings!.user as any).program?.dateFormat;
    });
  }
}
