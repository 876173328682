import { Component, OnInit } from '@angular/core';
import { ContentGqlService, Content, AuthStoreService, SiteLanguageStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { IsLoadingService } from '@service-work/is-loading';
import { LanguageItem } from 'src/app/core/model/core/language-item';

@UntilDestroy()
@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  languageMenu: LanguageItem[];
  language: LanguageItem | undefined;
  siteLanguage: string;
  userSettingsLanguage: string;

  constructor(
    private siteLanguageStore: SiteLanguageStoreService,
    private isLoadingService: IsLoadingService,
    private contentGql: ContentGqlService,
    private translate: TranslateService,
    private authStore: AuthStoreService
  ) {}

  ngOnInit(): void {
    this.getLanguageMenu('en-GB');
    this.siteLanguageStore.language$.pipe(untilDestroyed(this)).subscribe((language) => {
      if (language) {
        this.translate.use(language);
        this.siteLanguage = language;
      }
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this)).subscribe((userSettings) => {
      if (userSettings?.language) {
        this.userSettingsLanguage = userSettings.language;
        if (!this.siteLanguage) {
          this.translate.use(userSettings.language);
        }
      }
    });
  }

  setLanguage(language: string): void {
    this.siteLanguageStore.language = language;
  }

  getLanguageMenu(language: string): void {
    const siteUrl = new URL(window.location.href);
    const languageMenuPath = `${siteUrl.origin}/menu-language-menu`;

    this.isLoadingService.add(
      this.contentGql.getContentByUrlPath(languageMenuPath, language, true).subscribe((menuResponse) => {
        this.languageMenu = this.transformMenuItems(menuResponse.children);
        this.language = this.languageMenu.find(
          (lang) => lang.language === (this.siteLanguage || this.userSettingsLanguage)
        );
      })
    );
  }

  private transformMenuItems(menuItems: Content[]): LanguageItem[] {
    return menuItems.map((menuItem) => {
      const nameField = menuItem.fields.find((f) => f.name === 'Name');

      return {
        language: menuItem.properties.language?.value,
        name: nameField!.value.value,
      };
    });
  }
}
