import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GenericListColumn, GenericListComponent, GenericListState } from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-list',
  templateUrl: './custom-list.component.html',
  styleUrls: ['./custom-list.component.scss'],
})
export class CustomListComponent {
  @ViewChild(GenericListComponent, { static: true }) invoiceList: GenericListComponent;

  @Input() dataProvider: any[] = [];
  @Input() columnsProvider: GenericListColumn[] = [];
  @Input() noResultsMessageDisplayKey = `&nbsp;&nbsp;&nbsp;&nbsp;${this.translate.instant('No records found.')}`;
  @Input() rowHeight = '40px';
  @Input() totalCount = 0;
  @Input() itemSelectableFunction: (item: any) => boolean;
  @Input() showPagination = true;

  itemsPerPageDisplayKey = `${this.translate.instant('Items per page')}:`;
  entriesDisplayKey = '';

  @Output() stateChanged: EventEmitter<GenericListState> = new EventEmitter();

  constructor(protected translate: TranslateService) {}

  get selectedItems() {
    return this.invoiceList.selectedItems;
  }
}
